<script setup>
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  errorMessage: {
    type: String,
    default: '',
  },
})
const emits = defineEmits(['onPhoneCodeSelect'])

const phoneNumber = defineModel('phoneNumber')
const phoneCode = defineModel('phoneCode')

const onPhoneCodeSelect = (phoneCode) => {
  emits('onPhoneCodeSelect', phoneCode)
}
</script>

<style lang="scss">
@import 'assets/css/components/global/nu-phone-number.css';
</style>

<template>
  <div class="nu-phone-number d-flex flex-column width-responsive">
    <label class="nu-phone-code-label mb-2">{{ label }}</label>
    <div class="d-flex">
      <NuPhoneCode @onSelect="onPhoneCodeSelect" />
      <NuInput
        :error-message="errorMessage"
        :min="0"
        id="phoneNumber"
        class="width-full"
        type="number"
        v-model="phoneNumber"
      />
    </div>
  </div>
</template>
